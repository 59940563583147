import React, { useState, useEffect } from 'react';
import {A} from 'hookrouter';

function Home(props) {
    return (
        <div className="flex flex-col w-screen h-screen justify-center items-center">
            <div className="text-2xl font-bold">CONVERSION</div>
            <div className="text-gray-500 mb-6">nagara.co.id</div>
            <A href="/registration" className="p-4 hover:bg-gray-300 border border-gray-300 font-medium cursor-pointer">registration</A>
        </div>
    )
}

module.exports = Home;