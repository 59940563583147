import React, { useState, useEffect } from 'react';

function NotFound(props) {
    return (
        <div className="flex flex-col w-screen h-screen justify-center items-center">
            <div className="text-2xl font-bold">NOT FOUND</div>
            <div className="text-gray-500 mb-6">nagara.co.id</div>
        </div>
    )
}

module.exports = NotFound;