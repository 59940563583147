import React, { useState, useEffect } from 'react';
import {A} from 'hookrouter';

function Registration(props) {
    return (
        <div className="flex flex-col w-screen h-screen justify-center items-center">
            <div className="text-2xl font-bold">REGISTRATION</div>
            <div className="text-gray-500 mb-6">nagara.co.id</div>
            <A href="/" className="p-2 hover:bg-gray-300 font-medium cursor-pointer">Back To Home</A>
        </div>
    )
}

module.exports = Registration;