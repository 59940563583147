import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {useRoutes} from 'hookrouter';
import Home from './pages/home';
import NotFound from './pages/notfound';
import Registration from './pages/registration';

const routes1 = {
    '/': () => <Home />,
    '/registration': () => <Registration />
};

function App(props) {
    const routeResult = useRoutes(routes1);
    return routeResult || <NotFound />;
}

ReactDOM.render(<App />, document.getElementById("idmain"));
